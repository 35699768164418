





















































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted
} from "@vue/composition-api"

import Team from "@shared/Team"
import User from "@shared/User"

import { RtbButton } from "@/components/ui"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TeamTag from "@/components/GroupTeams/Common/TeamTag.vue"
import PillCountdown from "@/components/GroupTeams/Common/PillCountdown.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import AppUserCard from "@/components/UserCard/AppUserCard.vue"

import UserService from "@/services/user.service"
import { useViewer } from "@/use"
import useStore from "@/use/useStore"

import DiscussionGroupLabel from "./DiscussionGroupLabel.vue"
import useDiscussionGroup from "./useDiscussionGroup"
import useEnlargedUI from "@/use/useEnlargedUI"

export default defineComponent({
  name: "DiscussionGroup",
  components: {
    TeamTag,
    MissionCard,
    AppUserCard,
    RtbButton,
    ResizableText,
    PillCountdown,
    DiscussionGroupLabel
  },
  computed: {
    userStyle() {
      if (this.users.length === 1)
        return {
          width: "min(34vmin, 600px)"
        }

      if (this.users.length === 2)
        return {
          width: "min(30vmin, 600px)"
        }

      if (this.users.length === 3)
        return {
          width: "min(24vmin, 600px)"
        }

      return {
        width: "min(19vmin, 600px)"
      }
    },
    imageUrl() {
      return this.mission?.instructionsPhoto || this.mission?.photo
    }
  },
  setup() {
    const { store } = useStore()
    const { viewer } = useViewer()
    const {
      databaseRef,
      DEFAULT_TEAM_SIZE,
      displayedUsersTeamId,
      instructions,
      mission,
      roles,
      team,
      triangleShift,
      users
    } = useDiscussionGroup()

    const { enlargedUI } = useEnlargedUI()

    const isPresenter = computed(() => User.isPresenter(viewer.value))

    const isViewerHostLike = computed(
      () => store.getters["group/isViewerHostLike"]
    )

    const rows = computed(() => {
      const _users = users.value
      if (_users.length < 3) {
        return [_users]
      }
      const index = Math.floor(_users.length / 2)
      return [_users.slice(0, index), _users.slice(index)]
    })

    const viewerTeamId = computed(() => viewer.value?.teamID)

    const incompleteTeamID = computed(() => {
      const teams = Team.normalize(store.getters.chats)
      const usersGroupedByTeam = store.getters.onlineUsersGroupedByTeam ?? {}
      return teams.find(team => {
        const users = usersGroupedByTeam[team?.id] ?? []
        return !Team.isSpecialPurpose(team) && users?.length < teamSize.value
      })?.id
    })

    const isJoinable = computed(
      () =>
        incompleteTeamID.value === displayedUsersTeamId.value ||
        hasExitTeamBtn.value
    )

    const hasEnterTeamBtn = computed(
      () => viewerTeamId.value !== displayedUsersTeamId.value
    )

    const hasExitTeamBtn = computed(
      () => viewerTeamId.value === displayedUsersTeamId.value
    )

    const teamSize = computed(
      () => mission.value?.breakout?.size || DEFAULT_TEAM_SIZE
    )

    const endTime = computed(() => {
      const int = parseInt(store.getters.gameStatus.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    })

    function rotate() {
      databaseRef.value.set(triangleShift.value + 1)
    }

    function enterDefaultTeam() {
      return enterTeam(displayedUsersTeamId.value)
    }

    function enterTeam(teamId) {
      UserService.updateTeamId(viewer.value, teamId)
    }

    function exitTeam() {
      UserService.updateTeamId(viewer.value, null)
    }

    onMounted(() => {
      if (!isViewerHostLike.value) return

      if (!incompleteTeamID.value) return

      store.dispatch("group/updateCurrentGlobalTeam", incompleteTeamID.value)
      enterTeam(incompleteTeamID.value)
    })

    onBeforeUnmount(() => {
      if (isViewerHostLike.value && viewer.value.teamID) exitTeam()
    })

    return {
      enlargedUI,
      isJoinable,
      incompleteTeamID,
      endTime,
      isViewerHostLike,
      instructions,
      rotate,
      triangleShift,
      hasEnterTeamBtn,
      hasExitTeamBtn,
      enterDefaultTeam,
      exitTeam,
      viewerTeamId,
      team,
      displayedUsersTeamId,
      isPresenter,
      roles,
      users,
      rows,
      mission
    }
  }
})
