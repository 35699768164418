var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "listening-triangle",
      class: { "listening-triangle--enlarged": _vm.enlargedUI },
    },
    [
      _c(
        "MissionCard",
        { staticClass: "listening-triangle__card" },
        [
          _c("PillCountdown", {
            staticClass: "listening-triangle__timer",
            attrs: { endTime: _vm.endTime },
          }),
          _c(
            "div",
            { staticClass: "listening-triangle__instructions" },
            [
              _vm.imageUrl
                ? _c(
                    "div",
                    { staticClass: "listening-triangle__instructions-image" },
                    [_c("img", { attrs: { src: _vm.imageUrl } })]
                  )
                : _vm._e(),
              _c("ResizableText", {
                attrs: { message: _vm.instructions, transition: false },
              }),
            ],
            1
          ),
          _vm.isViewerHostLike
            ? _c(
                "div",
                { staticClass: "listening-triangle__controls" },
                [
                  _c(
                    "RtbButton",
                    {
                      staticClass: "listening-triangle__action mb-2",
                      attrs: { variant: "icon", circle: "", size: "lg" },
                      on: { click: _vm.rotate },
                    },
                    [
                      _c("SvgIcon", { attrs: { name: "sync-alt" } }),
                      _vm.triangleShift
                        ? _c(
                            "div",
                            {
                              staticClass: "listening-triangle__action-counter",
                            },
                            [_vm._v(" " + _vm._s(_vm.triangleShift) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isJoinable
                    ? [
                        _vm.hasEnterTeamBtn
                          ? _c(
                              "RtbButton",
                              {
                                staticClass: "listening-triangle__action",
                                attrs: {
                                  variant: "icon",
                                  circle: "",
                                  size: "lg",
                                  animated: "",
                                },
                                on: { click: _vm.enterDefaultTeam },
                              },
                              [_c("v-icon", [_vm._v("login")])],
                              1
                            )
                          : _vm.hasExitTeamBtn
                          ? _c(
                              "RtbButton",
                              {
                                staticClass: "listening-triangle__action",
                                attrs: {
                                  variant: "icon",
                                  circle: "",
                                  size: "lg",
                                  animated: "",
                                },
                                on: { click: _vm.exitTeam },
                              },
                              [_c("v-icon", [_vm._v("logout")])],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.isPresenter
        ? _c(
            "div",
            { staticClass: "listening-triangle__rows" },
            [
              _c("TeamTag", {
                staticClass: "listening-triangle__team-tag",
                attrs: { team: _vm.team },
              }),
              _vm._l(_vm.rows, function (users, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "listening-triangle__users" },
                  _vm._l(users, function (user) {
                    return _c(
                      "div",
                      {
                        key: user.id,
                        staticClass: "listening-triangle__user-column",
                      },
                      [
                        _c("AppUserCard", {
                          staticClass: "listening-triangle__user",
                          style: _vm.userStyle,
                          attrs: { user: user },
                        }),
                        _c(
                          "DiscussionGroupLabel",
                          {
                            staticClass: "listening-triangle__user-label",
                            attrs: { color: user.color },
                          },
                          [_vm._v(" " + _vm._s(user.label) + " ")]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }